
import './DetailsDashboard.css';

type inpParams = { progress: number }
export default function Progress({ progress }: inpParams) {

    const containerStyles = {
        height: 20,
        width: '100%',
        backgroundColor: "#e0e0de",
        borderRadius: 50,
        
      }
    
      const fillerStyles = {
        height: '100%',
        width: progress.toString()+'%',
        backgroundColor: '#00BFFF',
        borderRadius: 'inherit',
        align:'right'
      }
    
      const labelStyles = {
        padding: 5,
        color: 'white',
        fontWeight: 'bold'
      }

    return (

        <>
            <div style={containerStyles} >
                <div style={fillerStyles}>
                    <span style={labelStyles}>{progress+'%'}</span>
                </div >
            </div >
        </>



    )
}

