import React  from 'react';

import './Navigation.css'
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCookies } from 'react-cookie';



type inpParams = {Title:String,LoggedIn:boolean}
export default function Navigation({Title,LoggedIn}:inpParams) {

  let navigate = useNavigate();

   function handleChange(element: HTMLSelectElement) {
    if(element.value != "Admin"){
      navigate(`${element.value}`);
      element.value = "Admin";
    }else{

    }
    
   }

  const cook = useCookies(['userId']);

  function Logout(){
    cook[2]('userId')
    window.location.reload();
  }


  return(
    <div className="NavWrap">
        <div className="inline-block"><Link to="/"><img className="PECLOGO" src="/PECLogo.svg" alt="PECLOGO"/></Link></div>
        <h2 id="TitleNav" className="inline-block m-auto ">{Title}</h2>
        { LoggedIn ?<button className='inline-block float-right py-7 pr-20'  onClick={Logout}>Logout</button> : null}
        { LoggedIn ? <>
          
          <select onChange={event => handleChange(event.target)} defaultValue="Admin" className='inline-block float-right py-7 mr-10 '  id="grid-state" name="template" required>
             <option hidden={true}>Admin</option>
             <option  className='optionAdminDrop' value="AllModels" >  <Link to="/Admin">All Models</Link></option>
             <option  className='optionAdminDrop' value="AppBundles">App Bundles</option>

          </select>
{/**? 
<button className='inline-block float-right py-7 pr-10'  >
          <Link to="/Admin">
        
        
        Admin</Link></button>
        */}
</>
 : null}
    </div>
    
  );
}