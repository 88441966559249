import { useParams, useLocation } from 'react-router-dom';
import './DetailsDashboard.css';
import ProjectDetails from './ProjectDetails'
import ModelsProvided from './ModelsProvided'
import DetailsHelper from './DetailsHelper'
import { useState, useRef, useEffect } from 'react';
import MembersList from './MembersList';


type inpParams = { token: string }
export default function DashboardDetails({ token }: inpParams) {

    const location = useLocation();
    const helper = new DetailsHelper();
    const { id } = useParams();
    const [project, setProject] = useState(undefined);




    const effectRan = useRef(false);


    useEffect(() => {

        if (!effectRan.current) {

            const asyncfunc = async () => {
                let projectState = location.state
            
                if (!projectState) {
                    projectState = (await helper.getProject(token, id || ""))
                }else{
                    projectState = projectState.project
                }
                var titleElement = window.document.getElementById("TitleNav")
                if(titleElement){
                    titleElement.innerText = projectState.name
                }
                
                setProject(projectState)

            }


            asyncfunc()

        }

        return () => { effectRan.current = true };

    }, [])


    return (
        <div className="deatailsDashboard">
            {project !== undefined ?

                <>
                    <div className='DetailsDiv'>
                    <div className="detailsMore w-full" >
                    <div className="relative p-6 flex-auto">
                        <ProjectDetails project={project}></ProjectDetails>
                        
                        </div>
                        </div>
                    </div>
                    <div className='ModelsDiv'>
                    <div className="detailsMore">
                        <ModelsProvided project={project} token={token}></ModelsProvided>
                        <br/>
                        <MembersList token={token} project={project}></MembersList>
                        </div>
                    </div>
                </>
                : null}
        </div>
    )
}


