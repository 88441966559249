
import './DetailsDashboard.css';
import MembersHelper from './MembersHelper'
import { useState, useRef, useEffect } from 'react';
import { ACCProjectResponse } from '../../models/ACCProjects.model'
import { Members, key, phoneType, access } from '../../models/ACCProjectMembers.model';



type inpParams = { token: string, project: ACCProjectResponse }
export default function MembersList({ token, project }: inpParams) {


    const helper = new MembersHelper();

    const initMember = {
        email: "",
        id: "",
        name: "",
        firstName: "",
        lastName: "",
        autodeskId: "",
        analyticsId: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        stateOrProvince: "",
        postalCode: "",
        country: "",
        imageUrl: "",
        phone: {
            number: "",
            phoneType: phoneType.mobile,
            extension: "",

        },
        industry: "",
        aboutMe: "",
        accessLevels: {
            accountAdmin: false,
            projectAdmin: false,
            executice: false,
        },
        addedOn: "",
        updatedAt: "",
        comapnyId: null,
        companyName: null,
        roleIds: [""],
        roles: [{
            id: "",
            name: ""
        }],
        status: "",
        products: [{
            key: key.autoSPecs,
            access: access.none
        }],
        last_sign_in:"",
        job_title:"",
        new_job_title:""
    }

    const [members, setMembers] = useState<Members[]>([initMember]);
    const [ShowMemberModal, setShowMemberModal] = useState(false);
    const [allowAddMember, setAllowAddMember] = useState(true);
    const [searchMembers, setSearchMembers]= useState<Members[]>([initMember]);


    const effectRan = useRef(false);


    useEffect(() => {

        if (!effectRan.current) {

            const asyncfunc = async () => {
                setMembers((await helper.getMembers(token, project.id || "")));
                setSearchMembers((await helper.getUsersForForm(token)));
                
                setAllowAddMember(false)
            }
            

            asyncfunc()

        }

        return () => { effectRan.current = true };

    }, [])

    async function addMemberRequested(email:string){
        let adminAccessObject = window.document.getElementById("ProjectAdmin") as HTMLOptionElement
        let adminAccess = "member";
        if(adminAccessObject){adminAccess = adminAccessObject.value}

        let docsAccessObject = window.document.getElementById("DocumentManagement") as HTMLOptionElement
        let docsAccess = "member";
        if(docsAccessObject){docsAccess = docsAccessObject.value}

        const products =[
            {
                key:"projectAdministration",
                access: adminAccess
            },
            {
                key:"docs",
                access: docsAccess
            }
        ]
        const reqBody = {
            email:email,
            products:products
        }
        helper.addUserToProject(token,reqBody,project.id)
        setTimeout(async ()=>{
        setMembers((await helper.getMembers(token, project.id || "")));
    },1000)
    }

    async function submitSearch(stringSearch: string){
        setSearchMembers((await helper.getUsersForForm(token,stringSearch)));      
        setMembers((await helper.getMembers(token, project.id || "")));
        
      }


    return (
        <>
<div className='ActionsHead'> <a className='DetailsHeaders'> Project Members</a>
<a className=" bg-blue-200 text-black active:bg-blue-500 
      font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1" href={"https://acc.autodesk.com/project-admin/members/projects/" + project.id + "?page=1"} target="_blank" rel="noopener noreferrer">Manage Members</a>

            <button
                className=" bg-blue-200 text-black active:bg-blue-500 
      font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                type="button"
                onClick={() => setShowMemberModal(true)}
                disabled={allowAddMember}
            >
                Add Member
            </button>

            </div>
            <br/>

            <table className="  DetailsTables shadow-lg bg-white  ">
                <thead>
                    <tr>

                        <th className="bg-red-100 border text-left px-8 py-4">Project Admin</th>
                        <th className="bg-red-100 border text-left px-8 py-4">Document Management</th>
                        <th className="bg-red-100 border text-left px-8 py-4">Email</th>
                        <th className="bg-red-100 border text-left px-8 py-4">Company</th>
                        <th className="bg-red-100 border text-left px-8 py-4">Roles</th>


                    </tr>
                </thead>
                <tbody>
                    {members.map(item => {
                        return (
                            <tr key={item.id}>
                                <td>
                                    {item.products ? <>{item.products.find(e => e.key === key.projectAdministration)?.access === "administrator" ? <span className="flex justify-center">✔️</span> : null}</> : null}
                                </td>
                                <td>
                                    {item.products ? <>{item.products.find(e => e.key === key.docs)?.access} </> : null}
                                </td>

                                <td>{item.email}</td>
                                <td>{item.companyName}</td>
                                <td>{
                                    item.roles.map(function (rol, i) { return (<>{rol.name}</>) })
                                }
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>


            {ShowMemberModal ? (
                <>
                    <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur-sm">
                        <div className="relative w-auto my-6 mx-auto max-w-6xl">
                            <div className="border-4 rounded-2xl shadow-lg relative flex flex-col min-w-max bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                                    <h3 className="text-3xl font=semibold">Add Member</h3>
                                    <button
                                        className="bg-transparent border-0 text-black float-right"
                                        onClick={() => setShowMemberModal(false)}
                                    >

                                    </button>
                                </div>
                                <div className="relative p-6 flex-auto">

                                

                                            <div className="w-full  px-3 mb-6 md:mb-0 border-b-2">
                                                <h3 className="text-xl font-semibold">Permissions</h3>
                                                
                                            </div>
                                            <br/>


                                            <div className="flex flex-wrap -mx-3 mb-6">
                                    <div className="w-full md:w-1/2 px-3">
                                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                                            Project
                                        </label>
                                        <div className="relative">
                                            <select defaultValue="administrator" className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="ProjectAdmin" name="ProjectAdmin">
                                                <option value="none"></option>
                                                <option value="administrator">Administrator</option>

                                            </select>
                                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="w-full md:w-1/2 px-3">
                                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                                            Docs
                                        </label>
                                        <div className="relative">
                                            <select defaultValue="administrator" className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="DocumentManagement" name="DocumentManagement">
                                                <option value="none"></option>
                                                <option value="administrator" >Administrator</option>
                                                <option value="member">Member</option>

                                            </select>
                                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="w-full  px-3 mb-6 md:mb-0 border-b-2">
                                                <h3 className="text-xl font-semibold">Users</h3>
                                                
                                            </div>
                                            <br/>

                                    <div className="w-full  px-3 mb-6 md:mb-0">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                                                   Search Users
                                                </label>
                                                <input onChange={(e) => { submitSearch(e.target.value);}} className=" appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" type="text" placeholder="User" name="memberSearch" id="memberSearch" required />
                                                <ul>
                                                    {searchMembers.map(memb=>{return(
                                                        <>
                                                        {members.map(item => item.id).includes(memb.id) ? 
                                                        <li className="border-b-2"><button disabled={true} className=" text-white bg-zinc-500 active:bg-yellow-700 font-bold uppercase text-xs px-1 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1">added</button> {memb.email} </li>
                                                        :
                                                        <li className="border-b-2"><button onClick={() => addMemberRequested(memb.email)} className=" text-white bg-green-400 active:bg-yellow-700 font-bold uppercase text-xs px-1 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1">add</button> {memb.email} </li>
                                                        }
                                                        </>
                                                    )})}
                                                    
                                                </ul>
                                                

                                                <input  className="hidden" type="text" name="member" id="member" required />
                                                <br/>
                                            </div>


                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                                        type="button"
                                        onClick={() => setShowMemberModal(false)}
                                    >
                                        Close
                                    </button>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    )
}


