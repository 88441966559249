import React, { useState,useEffect,useRef } from 'react';
import './App.css';
import ProjectList from './components/ProjectList/ProjectList';
import Login from './components/Login/Login'
import Naviagtion from './components/Navigation'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {ForgeAuthService} from './components/auth/ForgeAuthHelper'
import DashboardDetails from './components/ProjectInfo/DetailsDashboard';
import AppBundles from './components/AdminPages/AppBundlesLayout';

import AdminPageLayout from './components/AdminPages/AdminPageLayout';
import ModelsLevelsAssign from './components/ProjectInfo/SetLevels';


function App() {
  
  const [token,setToken] = useState(false);
  const [access,setAccess] = useState("");
  

  const titleMap = [
    {path: '/', title:'Project List'},
    {path:'/Details/:id',title:"Project Details"}
   
  ]


  const effectRan = useRef(false);


  useEffect(()=>{

    if (!effectRan.current) {
    let authService = new ForgeAuthService()
    
    let curToken = authService.getPublicToken()

    
      curToken.then((tok)=>{
  
        if(!tok?.access_token){
          console.log("not authenticated")
          
          setToken(false)
        }else{
          setToken(true)
          setAccess(tok.access_token)
          //console.log("TOKEN USED",tok.access_token)
          console.log("authenticated")
        }
      })
      setTimeout(()=>{},20000)

    }

    return () => {effectRan.current = true};
    
  },[])
 
  

  if(!token) {
    return (
    <> 
    <BrowserRouter>
    <Naviagtion Title={""} LoggedIn={false}></Naviagtion>
    <Login />
    </BrowserRouter>
    </>
    )
  }
  return (
    <div className="wrapper">
   
    <BrowserRouter>
    <Naviagtion Title={(titleMap.find((el)=>el.path === window.location.pathname)?.title) || ""} LoggedIn={true}></Naviagtion>
      <Routes>
        <Route path="/" element={<ProjectList  token={access} />}></Route>
        <Route path="/Details/:id" element={<DashboardDetails token={access}/>}></Route>
        <Route path="/AllModels" element={<AdminPageLayout token={access} />}></Route>
        <Route path="/AppBundles" element={<AppBundles token={access} />}></Route>
        <Route path="/AssignLevels" element={<ModelsLevelsAssign token={access}/>}></Route>
       
      </Routes>
    </BrowserRouter>
  </div>
  );

}

export default App;
