
export interface Activities{
    commandLine: string[];
    id:string;
    engine:string;
    appbundles:string[];
    description:string;
    file:File;
}

export class AppBundleRequest{
    bundleid:string;
    engine:string;
    description:string;
    file:File;
    alias:string;
    version:string;

    constructor( bundleid:string,engine:string,description:string,file:File,alias:string,version:string){
        this.bundleid = bundleid;
        this.engine = engine;
        this.description = description
        this.file = file;
        this.alias = alias;
        this.version = version;
    }
}