export interface Members{
    email:string;
    id:string;
    name:string;
    firstName:string;
    lastName:string;
    autodeskId:string;
    analyticsId:string;
    addressLine1:string;
    addressLine2:string;
    city:string;
    stateOrProvince:string;
    postalCode:string;
    country:string;
    imageUrl:string;
    phone:phone;
    industry:string;
    aboutMe:string;
    accessLevels:accessLevels;
    addedOn:string;
    updatedAt:string;
    comapnyId:null|string;
    companyName:null|string;
    roleIds: string[];
    roles:  role[];
    status:string;
    products:product[];
    last_sign_in:string;
    job_title:string;
    new_job_title:string;
}

interface product{
    key:key;    
    access:access;
}

export enum key{
    autoSPecs = "autoSPecs",
    build = "build",
    cost = "cost",
    designCollabaration = "designCollabaration",
    docs = "docs",
    insight = "insight",
    modelCoordination = "modelCoordination",
    projectAdministration = "projectAdministration",
    takeoff = "takeoff"
}

export enum access{
    administrator = "administrator",
    member = "member",
    none = "none"
}

export interface role{
    id:string;
    name:string;
}



interface accessLevels{
    accountAdmin:boolean;
    projectAdmin:boolean;
    executice:boolean;
}

interface phone{
    number:string;
    phoneType:phoneType;
    extension:string

}

export enum phoneType{
    home = "home",
    mobile = "mobile",
    office = "office"
}