import axios, { AxiosResponse } from 'axios'
import { environment } from '../../environments/environment';
import { Activities } from '../../models/AppBundle.model';

const BACKEND_URL = environment.baseApiUrl

export default class AppBundleHelper{


    async getEngineHealth(token:string): Promise<string>{
        let returnable = ""

            let url=`${BACKEND_URL}/health/`

         var options = {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ${token}`
            }

        };

        returnable = (await axios.get(`${url}Revit`, options)).data.Status


        return returnable;
    }

    async getEngines(token:string): Promise<string[]>{
        

            let url=`${BACKEND_URL}/engines`

         var options = {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ${token}`
            }

        };

        let activites = (await axios.get(url,options)).data
        console.log(activites)
        return activites
    }

    async getActivities(token:string):Promise<Activities[]>{
        
        let url = `${BACKEND_URL}/getActivities`

        var options = {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }

        let activites = (await axios.get(url,options)).data
        return activites
    }

    async DeleteActivity(ActivityID:string,token:string){
        let url = `${BACKEND_URL}/deleteActivity`

        var options = {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data:{
                Activtyid:ActivityID
            }
        }
        

        await axios.delete(url,options)
       
    }

    async DeleteBundle(bundleid:string,token:string){
        let url = `${BACKEND_URL}/deleteAppBundle`

        var options = {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data:{
                bundleid:bundleid
            }
        }
        
        await axios.delete(url,options)
       
    }


    async postAppBundle(token:string,body:any){

        var options = {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        };
       
        let url=`${BACKEND_URL}/createAppBundle`
        
       
        let resp = await axios.post(url,body, options)
        
  
       
    }
}