import React, { useState, useEffect, useRef } from 'react';
import { RevitModel } from '../../models/RevitModels.model'
import AdminHelper from './AdminHelper';
import ClipLoader from "react-spinners/ClipLoader";

type inpParams = { token: string }
export default function ModelsByVersion({ token }: inpParams) {

    const [rows, setRows] = useState<Array<RevitModel>>([]);
    const [modelsLoading, setModelsLoading] = useState(true);
    const [modelYear, setModelYear] = useState("All");
    const effectRan = useRef(false);

    let helper = new AdminHelper()

    useEffect(() => {

        if (!effectRan.current) {

            const asyncfunc = async () => {
                setRows(await helper.getRevitModelsByVersion(token, modelYear))
                setModelsLoading(false)
            }

            asyncfunc()

        }

        return () => { effectRan.current = true };

    }, []);

    async function refreshModels() {
        helper.refreshRevitModelsByVersion(token)
        window.alert("Models are refreshing. Come back later to see updated results.")

    }

    async function modelYearChanges(year: string) {
        setModelYear(year)
        setModelsLoading(true)
        setRows(await helper.getRevitModelsByVersion(token, year))
        setModelsLoading(false)
    }

    function exportToCsv() {

        let ArrofObj: any = [];
        rows.map(item => {
            ArrofObj.push({ Project: item.project, Name: item.name, link: "https://acc.autodesk.com/docs/files/projects/" + item.projectId + "?folderUrn=" + item.parentFolder.id, CreatTime: item.createTime, RevitVersion: item.RVTVersion, folder: item.parentFolder.name,platform:item.platform,status:item.status })
        })

        let csv = '';

        // Extract headers
        const headers = Object.keys(ArrofObj[0]);
        csv += headers.join(',') + '\n';

        // Extract values
        ArrofObj.forEach((obj: any) => {
            const values = headers.map(header => obj[header]);
            csv += values.join(',') + '\n';
        })


        var downloadLink = document.createElement("a");
        var blob = new Blob(["\ufeff", csv]);
        var url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = "data.csv";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);


    }

    return (
        <>

            {modelsLoading ?

                <>
                    <a className='DetailsHeaders'> Loading Revit models by Revit version.</a>

                    <br /><br /><br /><br /><div className=" DetailsTables flex items-center justify-center">  <ClipLoader size={100}></ClipLoader> </div></> :
                <>
                    <a className='DetailsHeaders'> Revit models by Revit version.



                    </a>




                    <div className='tableContainer w-11/12 flex justify-center'>

                        <div>
                            <div >
                                <div className="m-2 inline-block">
                                    <label className='label-text  text-sm font-medium text-gray-700 '> Model Version</label>
                                    <select onChange={(e) => { modelYearChanges(e.target.value) }} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state" name="template" required>
                                        <option>All</option>
                                        <option>2018</option>
                                        <option>2019</option>
                                        <option>2020</option>
                                        <option>2021</option>
                                        <option>2022</option>
                                        <option>2023</option>
                                        <option>2024</option>
                                        <option>2025</option>
                                    </select>


                                </div>

                                <div className="m-2 inline-block float-right">
                                    <button  className="float-right" onClick={refreshModels}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                                        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                                    </svg></button>

                                </div>

                                <div className="m-2 inline-block float-right">
                                    <button onClick={exportToCsv}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                                        </svg>
                                    </button>
                                </div>

                              
                                <div className="m-2 inline-block float-right">
                                    <a>Models Shown : {rows.length}</a>
                                </div>
                                <div className="m-2 inline-block">



                                </div>


                            </div>

                            <table className="shadow-lg bg-white  ">
                                <thead>
                                    <tr>
                                        <th className="bg-red-100 border text-left px-8 py-4">Project</th>
                                        <th className="bg-red-100 border text-left px-8 py-4">Model Name</th>
                                        <th className="bg-red-100 border text-left px-8 py-4">Created</th>
                                        <th className="bg-red-100 border text-left px-8 py-4">Revit Version</th>
                                        <th className="bg-red-100 border text-left px-8 py-4">Parent Folder</th>
                                        <th className="bg-red-100 border text-left px-8 py-4">Platform</th>
                                        <th className="bg-red-100 border text-left px-8 py-4">Status</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    
                                    {rows.map(item => {
                                        return (
                                            <tr key={item.id}>

                                                <td> {item.project}</td>
                                                <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" target="_blank" rel="noopener noreferrer" href={"https://acc.autodesk.com/docs/files/projects/" + item.projectId + "?folderUrn=" + item.parentFolder.id}>{item.name}</a></td>
                                                <td> {item.createTime}</td>
                                                <td> {item.RVTVersion}</td>
                                                <td> {item.parentFolder.name}</td>
                                                <td> {item.platform}</td>
                                                <td> {item.status}</td>
                                            </tr>
                                        );
                                    })}
                                    
                                </tbody>
                            </table>



                        </div>
                    </div>

                </>
            }

        </>

    );
}