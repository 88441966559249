


export enum project_model_templates{
    DefaultAcc = "dfbc6cb5-4fca-467f-ba08-9227029f155e"
}


export class ProjectValue {
    value?: number;
    currency?:string;
    constructor(value: number,currency: string){
        this.value = value;
        this.currency = currency
    }
};
/*
class ProjectTemplate {
    projectId: string;
    constructor(projectId: string){
        this.projectId = projectId
    }
}
*/
interface Products {
    key: string;
    status: string;
    icon: string;
    name: string;
}

enum classification {
    Production = "production",
    Template = "template",
    Component = "component",
    Sample = "sample"
}

export enum status {
    Active = "active",
    Pending = "pending",
    Archived = "archived",
    Suspended = "suspended"
}

//documentation: https://aps.autodesk.com/en/docs/acc/v1/reference/http/admin-projectsprojectId-GET/
export interface ACCProjectResponse {
    id: string;
    name: string;
    startDate?: string;
    endDate?: string;
    type: string;
    classification: classification;
    projectValue: ProjectValue;
    status: status;
    jobNumber: string;
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    stateOrProvince?: string;
    postalCode?: string;
    country?: string;
    latitude?: string;
    longitude?: string;
    timezone?: string;
    constructionType: string;
    deliveryMethod: string;
    contractType: string;
    currentPhase: string;
    businessUnitId: string;
    lastSignIn: string;
    imageUrl: string;
    thumbnailImageUrl: string;
    createdAt: string;
    updatedAt: string;
    memberGroupId: string;
    adminGroupId: string;
    accountId: string;
    sheetCount?: number;
    products: Products;
    platform: string;
    companyCount: number;
    memberCount: number;
}


export class ACCProjectRequest{
    name:string;
    template:{projectId:string};
    type:string;
    constructionType?:string;
    projectValue:{value:number;currency:string;}
    contractType?:string;
    startDate?:string;
    endDate?:string;

    constructor(name:string,templateId:string, type:string,constructionType:string| undefined,value:number,currency:string,contractType:string| undefined,startDate:string| undefined,endDate:string | undefined){
        
        if(startDate === ""){startDate = undefined}
        if(endDate === ""){endDate = undefined}
        
        this.name = name;
        this.template = {'projectId':templateId};
        this.type = type;
        this.constructionType = constructionType;
        this.projectValue = {'value':value,'currency':currency};
        this.contractType = contractType;
        this.startDate = startDate;
        this.endDate = endDate;

    }

}