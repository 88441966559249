import axios, { AxiosResponse } from 'axios'
import { environment } from '../../environments/environment';

const BACKEND_URL = environment.baseApiUrl


export default class DetailsHelper{


    async getProject(token:string,project_id:string) : Promise<AxiosResponse["data"]>{

        let url=`${BACKEND_URL}/ACC/Project/${project_id}`

        var options = {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        let resp = await axios.get(url, options)
        
       return resp.data
        
    }

}