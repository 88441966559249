import axios, { AxiosResponse } from 'axios'
import { environment } from '../../environments/environment';
import { RevitModel } from '../../models/RevitModels.model';

const BACKEND_URL = environment.baseApiUrl

export default class AdminHelper{

    constructor(){}

    async getUsersToDelete(token:string,user:string,pwd:string) : Promise<AxiosResponse["data"]>{
       
        let url=`${BACKEND_URL}/api/getAbandonedAD`
        
        var options = {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        var body={username:user,password:pwd};
        let resp = await axios.post(url,body, options)
        
       return resp.data
        
    }

    
    refreshRevitModelsByVersion(token:string) {
       
        let url=`${BACKEND_URL}/api/refreshRevitModelsByVersion`
        
        var options = {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            timeout:999999999999999

        };
    
        axios.get(url, options)
        
       
        
    }

    async getRevitModelsByVersion(token:string,modelYear:string): Promise<RevitModel[]> {
       
        let url=`${BACKEND_URL}/api/getRevitModelsByVersion/${modelYear}`
        
        var options = {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            timeout:999999999999999

        };
    
        let result = await axios.get(url, options)

        return result.data.models
        
       
        
    }

    async getUsersToUpdate(token:string,user:string,pwd:string) : Promise<AxiosResponse["data"]>{
       
        let url=`${BACKEND_URL}/api/getUpdateAD`
        
        var options = {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        var body={username:user,password:pwd};
        let resp = await axios.post(url,body, options)
        
       return resp.data
        
    }

}