
import './DetailsDashboard.css';
import { ACCProjectResponse } from '../../models/ACCProjects.model'


type inpParams = { project: ACCProjectResponse }
export default function ProjectDetails({ project }: inpParams) {


    return (


<>
  
            <a className='DetailsHeaders'> Project Details</a>
            <br />
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-full px-3 mb-6 md:mb-0">
                    <b>Name:</b> {project.name}
                </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <b>Project Type:</b> {project.type}
                </div>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <b>Construction Type:</b> {project.constructionType}
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <b>Project Value:</b> {project.projectValue.value}
                </div>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <b>Currency:</b> {project.projectValue.currency}
                </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-full px-3 mb-6 md:mb-0">
                    <b>Contract Type:</b> {project.contractType}
                </div>
               
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                   <b>Start Date:</b> {project.startDate && project.startDate !== ""? new Date(project.startDate).toLocaleString(): null}
                </div>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <b>End Date:</b> {project.endDate && project.endDate !== ""? new Date(project.endDate).toLocaleString(): null}
                </div>
            </div>

            
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-full px-3 mb-6 md:mb-0">
                    <b>Business Unit:</b> {project.businessUnitId}
                </div>
                
            </div>



          
           

            
</>

    )
}

