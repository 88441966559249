

export const constructionTypes = [
    "New Construction",
    "Renovation"
]




export const ContractType = [
   "Construction Management (CM) at Risk",
    "Design-Bid",
    "Design-Bid-Build",
    "Design-Build-Operate",
    "IPD"
]

export const Currency = [
    "USD",
    "AUD",
    "CAD",
    "EUR",
    "GBP",
    "ALL",
    "AZN",
    "BYR",
    "BRL",
    "BGN",
    "CNY",
    "HRK",
    "CZK",
    "DKK",
    "EEK",
    "HKD",
    "HUF",
    "ISK",
    "IRN",
    "IRR",
    "ILS",
    "JPY",
    "KZT",
    "KRW",
    "KPW",
    "KGS",
    "LVL",
    "LTL",
    "MKD",
    "MNT",
    "ANG",
    "NOK",
    "PKR",
    "PLN",
    "RON",
    "RUB",
    "SAR",
    "RSD",
    "SGD",
    "ZAR",
    "SEK",
    "CHF",
    "TWD",
    "TRL",
    "UAH",
    "UZS",
    "YER",
    "PHP",
    "NZD"

]