import axios from 'axios'
import { environment } from '../../environments/environment';
import { ForgeToken } from '../../models/forge-token.model';
import { Subject } from 'rxjs';

const BACKEND_URL = environment.baseApiUrl+ '/forge/';


export class ForgeAuthService {
    private forgeToken?: ForgeToken;
    private expires_at?: Date | null;
    private authStatusListener = new Subject<boolean>();
    
    

    constructor(){this.expires_at = new Date();}

   async getCallback(){
        const url = `${BACKEND_URL}v2/oauth/url`;
        let response = await axios.get(url, { withCredentials: true })
        return response.data
        
        
    }

    async autoLogin() {
        const token = await this.getToken();
        return !!token;
    }

  
    getAuthStatusListener() {
        return this.authStatusListener.asObservable();
    }


    async getInternalToken() {
        const token = await this.getToken();

        if (!token) {
            return null;
        }

        return {
            access_token: token.access_token,
            expires_in: await this.expiresIn()
        };
    }

    async getPublicToken() {
        const token = await this.getToken();
        
        return {
            access_token: token.access_token,
            expires_in: await this.expiresIn()
        };
    }

    private getToken() {
        return new Promise<ForgeToken>(async (resolve, reject) => {
            try {
                if (!this.forgeToken) {
                    this.forgeToken = await this.refreshToken();
                    if (this.forgeToken) {
                        this.expires_at = this.forgeToken.expires_at;
                        this.authStatusListener.next(true)
                    }
                }
                else if (await this.isExpired()) {
                    this.forgeToken = await this.refreshToken();
                    if (!this.forgeToken) {
                        this.expires_at = null;
                        this.authStatusListener.next(false);
                    }
                }
                if(this.forgeToken)
                    resolve(this.forgeToken);
            }
            catch (error) {
                console.error(error);
                reject(null);
            }
        });
    }

    private refreshToken() {
        return new Promise<ForgeToken>((resolve, reject) => {
            try {
                
                axios.get<{ message: string, token: ForgeToken }>(`${BACKEND_URL}v2/token`, { withCredentials: true })
                    .then(resp => {
                        this.forgeToken = resp.data.token;
                        resolve(resp.data.token);
                    });
            }
            catch (error) {
                console.error(error);
                reject(null);
            }
        });
    }

    private async isExpired() {
        if (!this.forgeToken) {
            return true;
        }
        return (new Date() > new Date(this.expires_at as Date));
    }

    private async expiresIn() {
        if (!this.forgeToken) {
            return 0;
        }

        const now = new Date();
        const expiresAt = new Date(this.expires_at as Date)
        return Math.round((expiresAt.getTime() - now.getTime()) / 1000);
    };



}