import React from 'react';
import './Login.css';
import {ForgeAuthService} from '../auth/ForgeAuthHelper'


export default function Login() {

  let authService = new ForgeAuthService()
  
  function onForgeLogin() {

    
        let result = authService.getCallback()
        result.then((url)=>{
          window.location.href = url+window.location.href
        })
      
  }

  return(
<div className="forge-login-pane">
  <h3>To access this app you must be authenticated through Forge.</h3>
  <button mat-raised-button onClick={onForgeLogin}>
      <img src="/autodesk_text.png" alt="autodesk" height="20"/> Sign In
  </button>
  
</div>
  )
}



