import React, { useState, useEffect, useRef } from 'react';
import { Members } from '../../models/ACCProjectMembers.model'
import AdminHelper from './AdminHelper';
import UsersToDelete from './UsersToDelete';
import UsersToUpdate from './UserRolesToUpdate';
import ClipLoader from "react-spinners/ClipLoader";
import ModelsByVersion from './ModelsByVersion';

type inpParams = {token:string}
export default function AdminPageLayout({token}:inpParams) {


    const [deleterows, setDeleteRows] = useState<Array<Members>>([]);
    const [updaterows, setUpdateRows] = useState<Array<Members>>([]);
    const [username, setUsername] = useState("init");
    const [password, setPassword] = useState("init");
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showUpdates,setShowUpdates] = useState(false);
    const [showDeletes,setShowDeletes] = useState(true);
    

    const usernameChanged = (e: any) => setUsername(e.target.value);
    const passwordChanged = (e:any) => setPassword(e.target.value);

    var helper = new AdminHelper();


    async function getAllAdminData(){
        setLoading(true);
        setDeleteRows((await helper.getUsersToDelete(token,username,password)))
        setUpdateRows((await helper.getUsersToUpdate(token,username,password)))
        setShowModal(false)
        setLoading(false);
    }

    return(
        <>

        
         {/*  <div className='tableContainer w-11/12 flex justify-center float-right'>
           {showDeletes ? null:
         <button
                className=" bg-blue-200 text-black active:bg-blue-500 
      font-bold px-3 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                type="button"
                onClick={() => {setShowDeletes(true);setShowUpdates(false);}}
            >
                Delete Users
            </button>
}
{showUpdates ? null:
            <button
                className=" bg-blue-200 text-black active:bg-blue-500 
      font-bold px-3 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                type="button"
                onClick={() => {setShowDeletes(false);setShowUpdates(true);}}
            >
                Update Users
            </button>
}
            </div>
        {showDeletes ?
        <UsersToDelete rows={deleterows}></UsersToDelete>
        :null}
        {showUpdates ? 
        <UsersToUpdate rows={updaterows} />
        :null}*/}
        <ModelsByVersion token={token}></ModelsByVersion>
        
        {showModal ? (
            
                <>
               
                    <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur-sm">
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            <div className="border-4 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                                    <h3 className="text-3xl font=semibold">Sign into active directory</h3>
                                    <button
                                        className="bg-transparent border-0 text-black float-right"
                                        onClick={() => setShowModal(false)}
                                    >

                                    </button>
                                </div>
                                <div className="relative p-6 flex-auto">
                                {loading ? <><br/><br/><br/><br/><div  className="flex items-center justify-center">  <ClipLoader size={100}></ClipLoader> </div></>:
                                    <form className="bg-gray-200 shadow-md rounded px-8 pt-6 pb-8 w-full">
                                        <div className="flex flex-wrap -mx-3 mb-6">
                                            <div className="w-full md:w-full px-3 mb-6 md:mb-0">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                                                    Username
                                                </label>
                                                <input className=" appearance-none block w-full bg-white text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-usernmae" type="text" placeholder="Username" name="username" required onChange={usernameChanged} />

                                            </div>
                                            <div className="w-full md:w-full px-3 mb-6 md:mb-0">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                                                    Password
                                                </label>
                                                <input className=" appearance-none block w-full bg-white text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-password" type="password" placeholder="password" name="password" required onChange={passwordChanged} />

                                            </div>
                                        </div>



                                    </form>
}
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                             
                                    <button
                                        className="text-white bg-green-500 active:bg-yellow-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                        type="button"
                                        onClick={() => getAllAdminData()}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </>
            ) : null}
        </>
    )
}