import axios from 'axios'
import { environment } from '../../environments/environment';
import { Members } from '../../models/ACCProjectMembers.model';

const BACKEND_URL = environment.baseApiUrl


export default class MembersHelper{

    async getMembers(token:string,project_id:string) : Promise<Members[]>{

        let url=`${BACKEND_URL}/ACC/Project/${project_id}/Members`

        let lMembers: Members[] = [];

        var options = {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        let resp = await axios.get(url, options)

        if(resp.data.pagination.nextUrl){
            lMembers = await this.recursiveMembers(resp.data.pagination.nextUrl,resp.data.results,options)
        }else{
            lMembers = resp.data.results
        }
        
       return lMembers
        
    }

    async recursiveMembers(url:string|undefined,members:Members[],options:any):Promise<Members[]>{

        if(url){
            let newresp = (await axios.get(url, options))
           return this.recursiveMembers(newresp.data.pagination.nextUrl,[...members,...newresp.data.results],options)
        }else{
            console.log(members)
            return members
        }

       
         
    }

    async getUsersForForm(token:string,search?:string):Promise<Members[]>{
        let url=`${BACKEND_URL}/ACC/Users`

        if(search){url+="?search="+search}

        let lMembers: Members[] = [];

        var options = {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        lMembers = (await axios.get(url, options)).data
        
        return lMembers
    }


    async addUserToProject(token:string,reqBody:any,project_id:string): Promise<string>{
        let url=`${BACKEND_URL}/ACC/Users/${project_id}`

       
     
        var options = {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        
        return (await axios.post(url,reqBody, options)).data
    }


}