import React, { useState,useEffect,useRef } from 'react';
import './ProjectList.css'
import ProjectHelper from './ProjectHelper'
import { Link } from 'react-router-dom';
import {ACCProjectResponse} from '../../models/ACCProjects.model'
import { environment } from '../../environments/environment';
import CreateProject from './CreateProject';

type inpParams = {token:string}
export default function ProjectList({token}:inpParams) {

  
  const [rows,setRows] = useState<Array<ACCProjectResponse>>([]);
  const [templates,setTemplates] = useState([{name:"",id:"",memberCount: 1}])
  const [projectTypes,setProjectTypes] = useState([{id:"",Name:""}])

  
  const [nextURL,setNext] = useState(undefined);
  const [previousURL, setPrevious] = useState(undefined);

  

  const helper = new ProjectHelper()
 
  
  const effectRan = useRef(false);


  useEffect(()=>{
    
      if (!effectRan.current ) {

        const asyncfunc = async() =>{

          var titleElement = window.document.getElementById("TitleNav")
          if(titleElement){
              titleElement.innerText = "Project List"
          }


            setTemplates(await helper.getTemplates(token))
            setProjectTypes(await helper.getProjectTypes())
           let res = await helper.getProjects(token)
           setRows(res.results)

         try{
           setNext(res.pagination.nextUrl)
           setPrevious(res.pagination.previousUrl)
           
         }catch(err){}
        }

       
         asyncfunc()  
        
    }
   
    return () => {effectRan.current = true};

  },[])

   async function getNext(url:string){

    if(url !== "" && url !== null) {
      let res = await helper.getProjects(token,url)
      setRows(res.results)
  
      setNext(res.pagination.nextUrl)
      setPrevious(res.pagination.previousUrl)
      
    }
    
   }

   

   async function submitSearch(stringSearch: string){

    let url : string | undefined = environment.baseApiUrl+"/ACC/Projects?sort=createdAt desc&filter[name]="+stringSearch+"&filterTextMatch=contains"
  
    if(stringSearch === undefined || stringSearch === ""){
      url = undefined
      console.log("undef")
    }

    let res = await helper.getProjects(token,url)
    console.log(url)
    setRows(res.results)

    setNext(res.pagination.nextUrl)
    setPrevious(res.pagination.previousUrl)
    
  }
   




  return (
    <>
     
      
      <div className='tableContainer w-11/12 flex justify-center'>
      <div>
       <div >
      <div className="m-2 inline-block">
      
        <input onChange={(e) => { submitSearch(e.target.value);}}  className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="NameSearch" type="text" placeholder='Search'/> 
       

      </div>
      <div className=' m-2 inline-block float-right'>
        <CreateProject templates={templates} token={token} projectTypes={projectTypes}></CreateProject>
      </div>

      </div> 
     
      <table className="shadow-lg bg-white  ">
        <thead>
        <tr>
       
          <th className="bg-red-100 border text-left px-8 py-4">Name</th>
          <th className="bg-red-100 border text-left px-8 py-4">Type</th>
          <th className="bg-red-100 border text-left px-8 py-4">Platform</th>
          
        </tr>
        </thead>
        <tbody>
        {rows.map(item => {
      return (
        <tr key={item.id}>
          <td><Link to={"/Details/"+item.id} state={{project:item}}>{ item.name }</Link></td>
          <td><Link to={"/Details/"+item.id} state={{project:item}}>{ item.type }</Link></td>
          <td><Link to={"/Details/"+item.id} state={{project:item}}>{ item.platform }</Link></td>
        </tr>
      );
    })}
        </tbody>
      </table>
      

    
  <div className="flex flex-row mx-auto float-right m-2">
  {previousURL ?  (
    
    <button onClick={()=>{getNext(previousURL);}} type="button" className="bg-gray-800 text-white rounded-l-md border-r border-gray-100 py-2 hover:bg-red-700 hover:text-white px-3">
      <div className="flex flex-row align-middle">
        <svg className="w-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd"></path>
        </svg>
        <p className="ml-2">Prev</p>
      </div>
    </button>

  ) : null}

    { nextURL ?(

    <button onClick={()=>{getNext(nextURL);}} type="button" className="bg-gray-800 text-white rounded-r-md py-2 border-l border-gray-200 hover:bg-red-700 hover:text-white px-3">
      <div className="flex flex-row align-middle">
        <span className="mr-2">Next</span>
        <svg className="w-5 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path>
        </svg>
      </div>
    </button>

    ): null}
  </div>
</div>
</div>
    
    </>
  );
}