import React, { useState, useEffect, useRef } from 'react';
import './ProjectList.css';
import { Currency, ContractType, constructionTypes } from '../../models/form-data.model'
import ClipLoader from "react-spinners/ClipLoader";
import { ACCProjectRequest } from '../../models/ACCProjects.model'
import ProjectHelper from './ProjectHelper';
import { environment } from '../../environments/environment';

type inpParams = { templates: { name: string, id: string,memberCount:number }[], token: string, projectTypes: { id: string, Name: string }[] }
export default function CreateProject({ templates, token, projectTypes }: inpParams) {

    const [showModal, setShowModal] = useState(false);
    const [nameValid, setNameValid] = useState("")
    const [customInput, setCustomInput] = useState("");
    const [MembersLoaded, setLoadedMembers] = useState(false);
    const [addingMembers,setAddingMembers] = useState(false);

    const [projectIdLoaded,setProjectIdLoaded] = useState("");

    const [nameTaken, setNameTaken] = useState(false);

    const [startsWithNumber, SetStartsWithNumber] = useState("");
    const [templateMembersCount, setTemplateMembersCount] = useState(1);

    let helper = new ProjectHelper()
    const [creatingProject, setCreatingProject] = useState(false);

    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
     

    async function sumbitExactSearch(stringSearch: string){


        const pattern = /\d{3,8}[-]\d{2,5}/g
        const matches = stringSearch.match(pattern)
        let matchFound = false;
        let url : string | undefined = ""
        if (matches && matches.length > 0 && stringSearch && stringSearch.match(pattern) != null) {
            url  = environment.baseApiUrl+"/ACC/Projects?sort=createdAt desc&filter[name]="+stringSearch+"&filterTextMatch=startsWith"
            matchFound = true;
    
        }else{
            url = environment.baseApiUrl+"/ACC/Projects?sort=createdAt desc&filter[name]="+stringSearch+"&filterTextMatch=equals"
      
        }

        
        if(stringSearch === undefined || stringSearch === ""){
          url = undefined
          console.log("undef")
        }
    
        let res = await helper.getProjects(token,url)
    
        SetStartsWithNumber("");
        setNameTaken(false)

        if(!matchFound){
            if(res.results && res.results.length > 0){
                setNameTaken(true)
              }
        }else{
            if(res.results && res.results.length > 0 && matches){
                SetStartsWithNumber(matches[0])
              }
        }
       
       
        
      }


      const iterateThroughUsersAdded = async () => {


        var options = {
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };
        

        let result = await helper.checkTotalTemplateMembers(projectIdLoaded, templateMembersCount,options)
    
        if(result){
            setLoadedMembers(result)
        }
        
    
    
    
      }

      const effectRan1 = useRef(false);
      useEffect(() => {
          if (MembersLoaded) {
             
                window.location.href = '/Details/' + projectIdLoaded
                setShowModal(false)
                setCreatingProject(false)
                setAddingMembers(false)
                return () => { effectRan1.current = true };
          }
          
      }, [MembersLoaded]);

      
      useEffect(() => {
          if(projectIdLoaded !== ""){
            delay(10000).then(()=>{
                setInterval(iterateThroughUsersAdded, 3000)
                setAddingMembers(true);
            });
          }
          
      }, [projectIdLoaded]);
    
    const submitProject = () => {


        let form = window.document.getElementById("createProjectFrom") as HTMLFormElement


        if (form) {

            let data = new FormData(form);
            if (!validateInput(data.get("name")?.toString())) {
                return
            }

            setCreatingProject(true);
            let body = new ACCProjectRequest(data.get("name")?.toString() || "", data.get("template")?.toString() || "", data.get("type")?.toString() || "", data.get("constructionType")?.toString(), Number(data.get("value")), data.get("currency")?.toString() || "USD", data.get("contractType")?.toString(), data.get("startDate")?.toString(), data.get("endDate")?.toString());

            setTemplateMembersCount(templates.find((t)=> t.id == data.get("template")?.toString() || "")?.memberCount || 1)
            helper.postProject(token, body).then((id) => {

                    setProjectIdLoaded(id)
                    form.reset()
                
            })
        }
    }



    const validateInput = (name: string | undefined): boolean => {

        let message = ""

        if (!name || name === "") {
            message = "Name cannot be empty!"
            return false
        }
        setNameValid(message)
        return true

    }
    function CustomProjectTypeSelected() {

        helper.postCreateProjectType(token, customInput);
    }


    return (
        <>
            <button
                className=" bg-blue-200 text-black active:bg-blue-500 
      font-bold px-3 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                type="button"
                onClick={() => setShowModal(true)}
            >
                Create Project
            </button>

            {showModal ? (
                <>
                    <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur-sm">
                        <div className="relative w-auto my-6 mx-auto max-w-3xl ">
                            <div className="border-4 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                                    <h3 className="text-3xl font=semibold">Create Project</h3>
                                    <button
                                        className="bg-transparent border-0 text-black float-right"
                                        onClick={() => setShowModal(false)}
                                    >

                                    </button>
                                </div>
                                <div className="relative p-6 flex-auto">
                                    <form id="createProjectFrom" className=" shadow-md rounded px-8 pt-6 pb-8 w-full">

                                        {creatingProject ? <>{addingMembers ? <><a className=" flex items-center justify-center bold text-3xl">Project Created!</a>
                                            <div className=" flex items-center justify-center"><ClipLoader size={100}></ClipLoader></div>
                                        <p className=" flex items-center justify-center">Project members are currently being added, this may take a minute... We will automatically navigate you to the project once they are added. You are free to navigate to the project details before this completes.  </p>
                                        </> 
                                        
                                        :
                                        
                                        <><a>Creating Project...</a><div className=" flex items-center justify-center"><ClipLoader size={100}></ClipLoader></div></>}</> :
                                            <>
                                                <div className="flex flex-wrap -mx-3 mb-6">
                                                    <div className="w-full md:w-full px-3 mb-6 md:mb-0">
                                                        {nameTaken ? <a className="text-red-600">Name already taken!</a> : null}
                                                        {startsWithNumber != "" ? <a className="text-red-600">Warning: Project exists that starts with {startsWithNumber}</a> : null}
                                                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                                                            Project Name *
                                                        </label>
                                                        <input  onChange={(e) => { sumbitExactSearch(e.target.value);}} className=" appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Project Name" name="name" required />
                                                        {nameValid === "" ? null : <a className="text-red-600"> {nameValid}</a>}
                                                    </div>
                                                </div>
                                                <div className="flex flex-wrap -mx-3 mb-6">
                                                    <div className="w-full md:w-3/5 px-3">
                                                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                                                            Project Template
                                                        </label>
                                                        <div className="relative">
                                                            <select defaultValue="dfbc6cb5-4fca-467f-ba08-9227029f155e" className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state" name="template" required>
                                                                {templates.map(item => { return (<option value={item.id}>{item.name}</option>) })}
                                                            </select>
                                                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="flex flex-wrap -mx-3 mb-6">
                                                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">

                                                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                                                            Project Type *
                                                        </label>
                                                        <div className="relative">
                                                            {  /* <select className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state" name="type" required>
                                                        <option></option>
                                                        {projectTypes.map(item =>{return(<option>{item.Name}</option>)})}
                                                        <option id="CustomProjectType"></option>
                                                    </select>
                                                    */
                                                            }
                                                            <input onChange={(e) => {
                                                                var secondsplit = e.target.value.split("Custom: ")[1]

                                                                if (secondsplit) { CustomProjectTypeSelected(); e.target.value = secondsplit; }
                                                                var containsInp = false;
                                                                projectTypes.forEach((type) => { if (type.Name.toLowerCase() === e.target.value.toLowerCase()) { containsInp = true; } })
                                                                if (!containsInp) { setCustomInput(e.target.value); } else { setCustomInput("") }



                                                            }} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" list="projectTypeInput" name="type" required></input>
                                                            <datalist className="appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="projectTypeInput">
                                                                <option></option>
                                                                {projectTypes.map(item => { return (<option>{item.Name}</option>) })}
                                                                {customInput === "" ? null : <option value={"Add Custom: " + customInput} id="CustomProjectType"> {customInput}</option>}
                                                            </datalist>

                                                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-full md:w-1/2 px-3">
                                                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                                                            Construction Type
                                                        </label>
                                                        <div className="relative">
                                                            <select className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state" name="constructionType">
                                                                <option></option>
                                                                {constructionTypes.map(item => { return (<option>{item}</option>) })}
                                                            </select>
                                                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="flex flex-wrap -mx-3 mb-6">
                                                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                                                            Project Value *
                                                        </label>
                                                        <input className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" defaultValue={0} required name="value" />

                                                    </div>
                                                    <div className="w-full md:w-1/2 px-3">
                                                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state" >
                                                            Currency *
                                                        </label>
                                                        <div className="relative">
                                                            <select className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state" required name="currency">
                                                                {Currency.map(item => { return (<option>{item}</option>) })}
                                                            </select>
                                                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="flex flex-wrap -mx-3 mb-6">

                                                    <div className="w-full md:w-1/2 px-3">
                                                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                                                            Contract Type
                                                        </label>
                                                        <div className="relative">
                                                            <select className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state" name="contractType">
                                                                <option></option>
                                                                {ContractType.map(item => { return (<option>{item}</option>) })}
                                                            </select>
                                                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="flex flex-wrap -mx-3 mb-6">
                                                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                                                            Project Start Date
                                                        </label>
                                                        <input className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="date" placeholder="Jane" name="startDate" />

                                                    </div>

                                                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                                                            Project End Date
                                                        </label>
                                                        <input className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="date" placeholder="Jane" name="endDate" />

                                                    </div>

                                                </div>

                                            </>

                                        }


                                    </form>
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                {!creatingProject ?<>
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className="text-white bg-green-500 active:bg-yellow-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                        type="button"
                                        onClick={submitProject}
                                        disabled={creatingProject}
                                    >
                                        Submit
                                    </button>
                                    </>
                                    :<>{addingMembers ? <>
                                     <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Close
                                    </button>
                                    <button onClick={()=>{window.location.href = '/Details/' + projectIdLoaded}}  className="text-black bg-green-300 active:bg-yellow-700 font-bold uppercase text-xs px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1" type="button">Take me there now</button>
                                    </>
                                    :
                                     null}</>}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}


        </>
    )
}



